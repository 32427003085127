/** @jsx jsx */
import { Link } from 'gatsby'
import * as React from 'react'
import { Box, Flex } from 'theme-ui'
import { jsx } from 'theme-ui'
import { GeometricAnimation } from '../../components/GeometricAnimation'
import { SEO } from '../../components/SEO'
import { StylizedTitle } from '../../components/StylizedTitle'
import { MainLayout } from '../../layouts/main'
import { PageProps } from '../../utils/types'

const Page = React.memo<PageProps<void>>(({ location }) => {
  return (
    <>
      <SEO title="Oups !" location={location} />
      <MainLayout>
        <StylizedTitle
          as="h1"
          sx={{
            fontSize: [3, 4],
            mb: 4,
          }}
        >
          Houston, we got a problem!
        </StylizedTitle>

        <Box
          sx={{
            fontFamily: 'body',
            fontWeight: 'body',
            fontSize: 1,
            letterSpacing: 0,
          }}
        >
          La page que vous recherchez n'existe pas.
          <br />
          <br />
          <Link to="/" sx={{ color: 'primary1' }}>
            Aller vers l'accueil
          </Link>
        </Box>

        <Flex
          sx={{
            mt: 4,
            backgroundColor: 'detail',
          }}
        >
          <GeometricAnimation sx={{ flexGrow: 1 }} />
        </Flex>
      </MainLayout>
    </>
  )
})
Page.displayName = 'Page'

export default Page
